import React from 'react';
import ReactDOM from 'react-dom';
import { Route, BrowserRouter, Switch } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import * as constants from './Constants'
import { BaseView } from './components/shared/BaseView'
import { HomeView } from './components/homeView/HomeView'
import { AboutView } from './components/aboutView/AboutView'
import { NotFoundView } from './components/notFoundView/NotFoundView'
import { GalleryView } from './components/galleryView/GalleryView';
import { ContactView } from './components/contactView/ContactView';
import css from './index.module.css';

ReactDOM.render((
    <BrowserRouter>
        <BaseView>
            <Switch>
                <Route exact path={constants.ROUTE_HOME} render={(props) => <HomeView {...props} />} />
                <Route path={constants.ROUTE_ABOUT} render={(props) => <AboutView {...props} />} />
                <Route path={constants.ROUTE_GALLERY} render={(props) => <GalleryView {...props} />} />
                <Route path={constants.ROUTE_CONTACT} render={(props) => <ContactView {...props} />} />
                <Route path="*" component={NotFoundView} />
            </Switch>
        </BaseView>
    </BrowserRouter>
),
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
