import React from 'react';
import CookieConsent from "react-cookie-consent";

export const Cookie = () => (
    <CookieConsent
        location="bottom"
        buttonText="Ok"
        cookieName="gdprCookie"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={150}
        onAccept={() => { }}
    >
        Acest website foloseste doar cookie strict necesare.{" "}
    </CookieConsent>
)