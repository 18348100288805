import React, { useState } from 'react';
import ContactStyles from './ContactView.module.css'


export const ContactView = () => {
  const [name, setName] = useState("");
  const [mail, setMail] = useState("");
  const [comment, setComment] = useState("");
  const [validationMessage, setValidationMessage] = useState(null);

  const validateInput = (name, mail, comment) => {
    if (!name) {
      return "Numele este obligatoriu";
    }
    if (!mail) {
      return "Mailul este obligatoriu";
    }
    else {
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (re.test(String(mail).toLowerCase()) === false) {
        return "Formatul mailului este incorect";
      }
    }
    if (!comment) {
      return "Va rugam lasati un comentariu";
    }
    return null;
  }

  const clearInput = () => {
    setName("");
    setMail("");
    setComment("");
  }

  const handleSubmit = (e) => {
    window.Email.send({
      Host: "mail.electroeuropa.ro",
      Username: "no-replay@electroeuropa.ro",
      Password: "7rv&Rq}eL#aQ",
      To: 'belciugvlad@electroeuropa.ro',
      From: "alexulici94@gmail.com",
      Subject: "This is the subject",
      Body: "And this is the body"
    }).then(
      message => console.log(message)
    );

    const validationResult = validateInput(name, mail, comment);
    if (validationResult) {
      setValidationMessage(validationResult);
    }
    else {
      setValidationMessage("Comentariul a fost trimis");
      clearInput();
    }

    e.preventDefault();
  }

  return (
    <div className={ContactStyles.container}>
      <address>
        <h1>CV ELECTRO EUROPA SRL</h1>
        <p>CUI: RO39013766, J5/598/13.03.2018, THEODOR SPERANTIA NR 1</p>
        <p>TEL: <a href="tel:+400744874357">0744-874-357</a></p>
        <p>EMAIL: <a href="mailto:office@electroeuropa.ro">office@electroeuropa.ro</a></p>
      </address>
      <div className={ContactStyles.formContainer}>
        <form className={ContactStyles.form} onSubmit={handleSubmit}>
          <div className={ContactStyles.formContent}>
            <div className={ContactStyles.formField}>
              <label className={ContactStyles.label}>Nume</label>
              <input type="text" name="name" className={ContactStyles.singleLineInput} onChange={(e) => setName(e.target.value)} value={name} />
            </div>
            <div className={ContactStyles.formField}>
              <label className={ContactStyles.label}>Email</label>
              <input type="text" name="mail" className={ContactStyles.singleLineInput} onChange={(e) => setMail(e.target.value)} value={mail} />
            </div>
            <div className={ContactStyles.formField}>
              <label className={ContactStyles.label}>Comentariu</label>
              <textarea type="text" name="comment" cols="30" rows="5" className={ContactStyles.multiLineInput} onChange={(e) => setComment(e.target.value)} value={comment} />
            </div>
            <input type="submit" value="Trimite" className={ContactStyles.submit} />
            <div className={ContactStyles.validationMessage}>{validationMessage}</div>
          </div>
        </form>
      </div>
    </div>
  )
};