import React from 'react';
import GalleryStyles from './GalleryView.module.css'

import Car1 from '../../assets/images/9.jpg'
import Car2 from '../../assets/images/10.jpg'
import Work1 from '../../assets/images/6.jpg'
import Work2 from '../../assets/images/7.jpg'

export const GalleryView = () => (
  <div className={GalleryStyles.gallery}>
    <div className={GalleryStyles.row}>
      <img src={Car1} alt="Logan" ></img>
      <img src={Car2} alt="Renault" ></img>
    </div>
    <div className={GalleryStyles.row}>
      <img src={Work1} alt="Sistem de iluminare" ></img>
      <img src={Work2} alt="Sistem de iluminare" ></img>
    </div>
  </div>
);

