import React from 'react';
import HomeViewStyles from './HomeView.module.css';

import { ReactComponent as IconConsulting } from '../../assets/icons/icon_consulting.svg';
import { ReactComponent as IconAnalisys } from '../../assets/icons/icon_analisys.svg';
import { ReactComponent as IconSecurity } from '../../assets/icons/icon_security.svg';
import { ReactComponent as IconSecurityCamera } from '../../assets/icons/icon_security_camera.svg';
import { ReactComponent as IconEnergy } from '../../assets/icons/icon_energy.svg';
import { ReactComponent as IconConditioning } from '../../assets/icons/icon_conditioning.svg';

export const HomeView = () => (
  <div className={HomeViewStyles.container}>
    <div className={HomeViewStyles.circle}>
      <div className={HomeViewStyles.angle}>
        <div className={HomeViewStyles.element}>
          <IconConsulting className={HomeViewStyles.icon}></IconConsulting>
          Consultanta
        </div>
      </div>
      <div className={HomeViewStyles.angle}>
        <div className={HomeViewStyles.element}>
          <IconAnalisys className={HomeViewStyles.icon}></IconAnalisys>
          Analiza de risc la securitate fizica
        </div>
      </div>
      <div className={HomeViewStyles.angle}>
        <div className={HomeViewStyles.element}>
          <IconSecurity className={HomeViewStyles.icon}></IconSecurity>
          Instalare sisteme tehnice de securitate
        </div>
      </div>
      <div className={HomeViewStyles.angle}>
        <div className={HomeViewStyles.element}>
          <IconSecurityCamera className={HomeViewStyles.icon}></IconSecurityCamera>
          Mentenanta sisteme de securitate
        </div>
      </div>
      <div className={HomeViewStyles.angle}>
        <div className={HomeViewStyles.element}>
          <IconEnergy className={HomeViewStyles.icon}></IconEnergy>
          Instalatii Electrice
        </div>
      </div>
      <div className={HomeViewStyles.angle}>
        <div className={HomeViewStyles.element}>
          <IconConditioning className={HomeViewStyles.icon}></IconConditioning>
          Instalatii Aparate Aer Conditionat
        </div>
      </div>
    </div >
  </div>
);