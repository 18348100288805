import React from 'react';
import BaseViewStyles from './BaseView.module.css';
import { Cookie } from './CookieConsent';

import { NavigationBar } from './NavigationBar';

export const BaseView = (props) => (
    <React.Fragment>
        <NavigationBar />
        <div className={BaseViewStyles.container}>
            {props.children}
        </div>
        <Cookie />
    </React.Fragment>
);
