import React from 'react';
import { Link } from 'react-router-dom'

import * as constants from '../../Constants'
import NavBarStyles from './NavigationBar.module.css'

import Logo from '../../assets/images/12.jpg'

export const NavigationBar = () => (
  <div className={NavBarStyles.navBarContainer}>
    <img src={Logo} alt="logo" className={NavBarStyles.logo}></img>
    <nav className={NavBarStyles.navBarList}>
      <div className={NavBarStyles.navBarButton}><Link to={constants.ROUTE_HOME}>Acasă</Link></div>
      <div className={NavBarStyles.navBarButton}><Link to={constants.ROUTE_ABOUT}>Despre noi</Link></div>
      <div className={NavBarStyles.navBarButton}><Link to={constants.ROUTE_GALLERY}>Galerie</Link></div>
      <div className={NavBarStyles.navBarButton}><Link to={constants.ROUTE_CONTACT}>Contact</Link></div>
    </nav>
  </div>
);