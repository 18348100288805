import React from 'react';
import AboutViewStyles from './AboutView.module.css'

export const AboutView = () => (
  <div className={AboutViewStyles.container}>
    <div className={AboutViewStyles.content}>
      <section>
        <p>Prin echipa care o avem va oferim o gama larga de servicii profesionale in domeniul sistemele de securitate si nu numai.CV Electro Europa va ofera servicii de:</p>
      </section>
      <section>
        <h1>Consultanta</h1>
        <p>Avand peste 10 ani de experienta, va putem oferii consultanta pentru a acoperii toate nevoile in
      materie de sisteme de securitate, detectie si semnalizare incendiu
        </p>
      </section>

      <section>
        <h1>Analiza de risc la securitate fizica</h1>
        <p>Începând cu data de 1 iulie 2018, și-a încetat efectele amânarea termenului până la care unitățile
        înființate înainte de 16 iunie 2012 erau exceptate de la realizarea analizei de risc la securitate fizică
        O analiză de risc la securitatea fizică, cu ajutorul căreia se identifică riscurile, zonele de impact,
        evenimentele și cauzele riscului și eventualele consecințe ale acestuia, pornește de la identificarea
        riscului. Mai precis, de la definirea parametrilor interni și externi care pot genera riscuri la securitatea
      fizică a unității.
      </p>
      </section>

      <section>
        <h1>Instalare sistem tehnice de securitate</h1>
        <p>Sistemul antiefractie sau alarma este principalul mijloc de avertizare impotriva patrunderii ilegale
        intr-un obiectiv. Sistemul antiefractie aduce un plus major securitatii unui obiectiv.
        Sistemul de supraveghere video este tot mai popular in randul utilizatorilor inclusiv casnici.
        Instalarea acestuia aduce un plus de siguranta, linistate utilizatorilor acestuia deoarece acesta poate
        fi accesat prin internet de oriunde in orice moment pentru a vedea starea obiectivului supravegheat.
        Sistemul de detectie semnalizare incendiu
        Un sistem de detectare, semnalizare și avertizare incendiu este o instalație care are scopul de a
        detecta și semnaliza în cât mai scurt timp posibil apariția unui incendiu. precum și acela de a alarma
        personalul, echipele de pompieri și oricare altă categorie de persoane aflate în zonă care pot ajuta la
        stingerea incendiului și la limitarea efectelor acestuia
        Sistem de control acces/porti
        Pentru a protejarea unui spatiu la cel mai inalt nivel este nevoie si de restricitonarea accesului in
        interiorul acestui, din acest motiv va oferim sisteme de control acces de cea mai buna calitate
      adaptate la nevoile fiecarui client.
      </p>
      </section>

      <section>
        <h1>Mentenanta sisteme de securitate</h1>
        <p>Functionarea corecta a unui sistem TEHNIC DE SECURITATE  (respectiv, ALARMA, CCTV, DETECTIE
          INCENDIU, CONTROL ACCES ) necesita o inspectie periodica. Incheierea unui contract de mentenanta
      </p>
      </section>

      <section>
        <h1>PLACEREA MESERIEI ADUCE PERFECTIUNEA MUNCII</h1>
        <p>Nu reprezinta o optiune, ci o obligatie conform legislatiei in domeniu. Conform Legea 301 / 2003,
        ANEXA 1 la normele metodologice ART. 4, SI INCENDIU CONFORM  Normativului
        P118/3 – 2015
        Asiguram service-ul echipamentelor aflat in posesia beneficiarului in cel mai scurt timp posibil. La cere putem
          completa, inlocui sau modifica sistemul aflat in posesia dvs.
    </p>
      </section>

      <section>
        <h1>Instalatii Electrice</h1>
        <p>Instalatiile electrice este una din instalatiile fara de care un obiectiv nu poate fuctiona, deoarece majoritate
        echipamentelor folosesc electricitate. Importanta acestuia este majora nu doar pentru functionalitate ce si
        pentru siguranta, de accea va oferim executia instalatiei electrice cu electricieni autorizati ANRE care vor
          acoperii toate nevoile dvs.
    </p>
      </section>

      <section>
        <h1>Instalatii Aparate Aer Conditionat</h1>
        <p>Instalarea corecta a aerului conditionat este foarte importanta pentru functionarea impecabila a acestui
        aparat. Instalarea unui asemenea aparat presupune efectuarea unor operatiuni standard adaptate la nevoile
    clientului. Va oferim instalarea profesionala si mentenanta a acestora in aceeasi masura.</p>
      </section>
    </div>
  </div>
);